import { PortableText } from '@portabletext/react'

import styles from './LargeIntroText.css'

export function LargeIntroText({ value }) {
  return <LargeIntroTextBase className={styles.component} {...{ value }} />
}

export function LargeIntroTextCentered({ value }) {
  return <LargeIntroTextBase className={styles.componentCentered} {...{ value }} />
}

function LargeIntroTextBase({ value, className }) {
  return (
    <div data-x='large-intro-text' className={cx(styles.componentBase, className)}>
      <div className={styles.text}>
        <PortableText {...{ value, components }} />
      </div>
    </div>
  )
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
const components = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
  },
  marks: {
    strong: ({ children }) => <strong className={styles.strong}>{children}&nbsp;</strong>,
  }
}
